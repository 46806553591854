import { memo, useCallback, useEffect, useState } from "react";
import { Heading } from "../../../../atoms/Heading";
import Search from "../../../../atoms/Search";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import style from "./index.module.scss";
// import NoContentCard from "../../../../atoms/NoContentCard";
import { deleteData, getData } from "../../../../../Services";
import View from "../../../../icons/View";
import EditBlue from "../../../../icons/EditBlue";
import CustomButton from "../../../../atoms/CustomButton";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Pagination from "../../../../atoms/Pagination";
import Delete from "../../../../icons/Delete";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import Modal from "../../../../atoms/modals/DeleteModal";
import Edit from "../../../../icons/EditBlue";
import CustomMenu from "../../../../molecules/CustomMenu";
import HorizontalMenu from "../../../../icons/HorizontalMenu";
import FilterComponent from "../../../../molecules/filters/FilterComponent";
// import { Rate_TypeData } from "../../../../../../utils/staticData";
import EyeIcon from "../../../../icons/eye.icon";
import Upload from "../../../../icons/Upload";
import { downloadReport } from "../../../../../Services/downloadService";
import moment from "moment";
import ClientForm from "./Form";
import NoContentCard from "../../../../atoms/NoContentCard";

const CategoryManagement = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t", "bid", "role"]);
  const role = cookies?.role ? atob(cookies.role) : "";
  const [employeeData, setEmployeeData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchData, setSearchData] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [editData, setEditData] = useState();
  const [loader, setLoader] = useState(true);
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });

  const menu = [
    ...(role && role !== "Viewer"
      ? [
          {
            icon: <Edit height="20" width="20" />,
            label: "Edit",
            onClick: () => {
              setEditData(selectedRow);
              setAnchorEl(null);
            },
          },
        ]
      : []),
    ...(role && role !== "Viewer"
      ? [
          {
            icon: <Delete />,
            label: "Delete",
            onClick: () => {
              setModalStateDelete({
                isModalOpen: true,
                name: selectedRow.name,
                modalId: selectedRow._id,
              });
              setAnchorEl(null);
            },
          },
        ]
      : []),
  ];

  const GetemployeeData = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "acategory",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          business_id: cookies.bid,
        },
      });
      setEmployeeData(res);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    setLoader(false);
    GetemployeeData();
  }, [itemsPerPage, currentPage, searchData]);

  const handleEdit = (data) => {
    navigate(`/dashboard/setting/price/add/${data?._id}`);
  };

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "category",
      headerName: "Expense Category",
      flex: 1,
      sortable: true,
    },
    {
      field: "sub_category",
      headerName: "Sub Expense Categories",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        const subCategories = Array.isArray(params.row.sub_category)
          ? params.row.sub_category.join(", ")
          : params.row.sub_category;

        return (
          <span type="button" title={subCategories}>
            {subCategories}
          </span>
        );
      },
    },

    {
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <span
              type="button"
              onClick={(event) => handleStatusMenu(event, params.row)}
            >
              <HorizontalMenu />
            </span>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              items={menu}
            />
          </>
        );
      },
    },
  ];

  // const getMenuList = () => {
  //   if (roleData && roleData?.["add/edit"] && roleData?.delete) {
  //     return menu;
  //   } else if (roleData && roleData?.delete) {
  //     return [menu[1]];
  //   } else if (roleData && roleData?.["add/edit"]) {
  //     return [menu[0]];
  //   } else return [];
  // };

  const CustomNoRowsOverlay = memo(() => {
    return loader ? (
      <>
        {" "}
        <div
          className="w-100  d-flex align-items-center justify-content-center"
          style={{ height: "70vh" }}
        >
          <span
            className="spinner-border spinner-border-lr ms-2"
            style={{ color: "#3b424a", width: "50px", height: "50px" }}
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </>
    ) : (
      <>
        <NoContentCard
          title="No Expense Categories added yet!"
          tag="Add Clients"
          type="Pricing"
          classes="d-flex align-items-center"
        />
      </>
    );
  });
  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };
  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: "acategory/delete",
        token: cookies.t,
        params: { id: modalStateDelete?.modalId },
      });
      if (res) {
        toast.success("Expense Category Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseDelete();
        GetemployeeData();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  return (
    <>
      {role && role !== "Viewer" && (
        <>
          <Heading className="h5 mt-2 lh-1">
            {false ? "Update" : "Create"} Expense Category
          </Heading>

          <ClientForm
            id={editData?._id}
            initialValues={editData}
            refetchData={GetemployeeData}
            setEditData={setEditData}
          />
        </>
      )}
      <div className="headingSearchWrap mt-2">
        <div>
          <Heading className="HeadingStyle">
            Expense Categories Management
          </Heading>
        </div>
        <div className="searchButton">
          <Search
            onSearchEmit={(data) => {
              setSearchData(data);
              setCurrentPage(1);
            }}
          />
        </div>
      </div>
      <CustomDatagrid
        rows={employeeData.data?.[0]?.roleData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        loader={loader}
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {employeeData?.data?.[0]?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={employeeData?.data?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
      <Modal
        isActive={modalStateDelete.isModalOpen}
        onClose={handleCloseDelete}
        onClick={handleDelete}
        title="Category"
      />
    </>
  );
};
export default CategoryManagement;
