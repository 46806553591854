import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
// import PageHeader from "../../../atoms/PageHeader";
// import BackBtn from "../../../../atoms/BackBtn";
// import PageCard from "../../../../atoms/PageCard";
// import   from "../../../../atoms/ ";
import Error from "../../../../atoms/Error";
import MyDropdown from "../../../../atoms/MyDropdown/index";
import MyInput from "../../../../atoms/MyInput/index";
import {
  countryCodeData,
  countryNameData,
} from "../../../../../helpers/countryCodes";
import { businessTypes } from "../../../../../helpers/staticData";
import CustomButton from "../../../../atoms/CustomButton";
import { DateSelector } from "../../../../atoms/MonthYearSelector";
import { replaceSpacesInImageUrl } from "../../../../../helpers/replaceSpaceInImageUrl";
import ImageSelector from "../../../../atoms/ImageSelector/ImageSelector";
import { useCookies } from "react-cookie";
import {
  getData,
  postFormData,
  patchFormData,
  postData,
  patchData,
} from "../../../../../Services/index";
import dayjs from "dayjs";
import Close from "../../../../icons/Close";
import EyeIcon from "../../../../icons/eye.icon";
import { statusData } from "../../../../../helpers/staticData";
// import { checkEmailExists } from "../../../../../services/basicGets";
import { capitalizeLetter } from "../../../../../helpers/capatalizeLetters";
import { Heading } from "../../../../atoms/Heading";
import MyTextArea from "../../../../atoms/MyTextArea";
import Breadcrumb from "../../../../atoms/Breadcrumb";
import { stateNameslist } from "../../../../../helpers/indianStates";
import { fetchPincodeService } from "../../../../../Services/pincodeService";
import Checkbox from "../../../../atoms/Checkbox";
import style from "./index.module.scss";
import { getExtension } from "../../../../../Services/getExtensionOfLink";
import Modal from "../../../../atoms/modals/Modal";
const ImageSelectorStyle = {
  borderRadius: "10px",
  border: "1px solid #EFEFEF",
  padding: "10px",
  width: "200px",
  objectFit: "contain",
};

const Add = ({ type = "add" }) => {
  const { id } = useParams();

  const [cookies] = useCookies(["t", "role"]);
  const role = cookies?.role ? atob(cookies.role) : "";
  const [plans, setPlans] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [emailError, setEmailError] = useState("");
  const [domainError, setDomainError] = useState(null);
  const [isSameAddress, setIsSameAddress] = useState(false);
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewURL, setIsViewURL] = useState(false);
  const [fileUrls, setFileUrls] = useState([]);
  const [isEmailChecked, setIsEmailChecked] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getData({
        endpoint: "business/getBusinessByID",
        token: cookies.t,
        params: { id },
      });
      if (res && res?.data) {
        if (
          res?.data?.official_address === res?.data?.registered_address &&
          res?.data?.official_address_city ===
            res?.data?.registered_address_city &&
          res?.data?.official_address_country ===
            res?.data?.registered_address_country &&
          res?.data?.official_address_state ===
            res?.data?.registered_address_state &&
          res?.data?.official_address_pin === res?.data?.registered_address_pin
        ) {
          setIsSameAddress(true);
        }
        if (res?.data?.logo && res?.data?.logo !== "null") {
          const url = res.data.logo;
          setFileUrls([{ url, name: url.split("/").pop() }]);
        }

        setInitialValues(res?.data);
      }
    };

    if (type === "edit") {
      fetchData();
    }
  }, []);

  const initialData = {
    name: initialValues?.name || "",
    sub_domain: initialValues?.sub_domain || "",
    email: initialValues?.email || "",
    business_email: initialValues?.business_email || "",
    business_type: initialValues?.business_type || "",
    official_address: initialValues?.official_address || "",
    official_address_city: initialValues?.official_address_city || "",
    official_address_country: initialValues?.official_address_country || "",
    official_address_state: initialValues?.official_address_state || "",
    official_address_pin: initialValues?.official_address_pin || "",
    registered_address: initialValues?.registered_address || "",
    registered_address_city: initialValues?.registered_address_city || "",
    registered_address_country: initialValues?.registered_address_country || "",
    registered_address_state: initialValues?.registered_address_state || "",
    registered_address_pin: initialValues?.registered_address_pin || "",
    primary_cc: initialValues?.primary_cc || "+91",
    alt_cc: initialValues?.alt_cc || "+91",
    primary_number: initialValues?.primary_number || null,
    alt_number: initialValues?.alt_number || null,
    contact_cc: initialValues?.contact_cc || "+91",
    gst: initialValues?.gst || null,
    contact_number: initialValues?.contact_number || null,
    contact_name: initialValues?.contact_name || "",
    status: initialValues?.status || "Pending",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Business Name is required"),
    sub_domain: Yup.string().required("SubDomain Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    business_email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    business_type: Yup.string().required("Business Type is required"),
    primary_cc: Yup.string().required("Country code is required"),
    alt_cc: Yup.string().required("Country code is required"),
    contact_cc: Yup.string().required("Country code is required"),
    primary_number: Yup.string().when("primary_cc", {
      is: "+91", // Condition: when primary_cc is '+91'
      then: () =>
        Yup.string()
          .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
          .required("Primary Phone Number is required"),
      otherwise: () =>
        Yup.string()
          .matches(/^[0-9]{7,15}$/, "Please Enter a valid Phone Number")
          .required("Primary Phone Number is required"),
    }),
    alt_number: Yup.string()
      .nullable()
      .when("alt_cc", {
        is: "+91", // Condition: when primary_cc is '+91'
        then: () =>
          Yup.string()
            .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
            .nullable(),
        otherwise: () =>
          Yup.string()
            .matches(/^[0-9]{7,15}$/, "Please Enter a valid Phone Number")
            .nullable(),
      }),
    gst: Yup.string()
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[A-Z0-9]{1}$/,
        "Invalid GST number"
      )
      .nullable(),
    official_address: Yup.string().required("Address is required"),
    official_address_country: Yup.string().required("Country is required"),
    official_address_pin: Yup.string().when("official_address_country", {
      is: "India",
      then: () => Yup.string().required("Pin is required"),
      otherwise: () => Yup.string().notRequired(),
    }),
    official_address_state: Yup.string().when("official_address_country", {
      is: "India",
      then: () => Yup.string().required("State is required"),
      otherwise: () => Yup.string().notRequired(),
    }),
    official_address_city: Yup.string().when("official_address_country", {
      is: "India",
      then: () => Yup.string().required("City is required"),
      otherwise: () => Yup.string().notRequired(),
    }),

    registered_address_country: Yup.string().required("Country is required"),
    registered_address_pin: Yup.string().when("registered_address_country", {
      is: "India",
      then: () => Yup.string().required("Pin is required"),
      otherwise: () => Yup.string().notRequired(),
    }),
    registered_address_state: Yup.string().when("registered_address_country", {
      is: "India",
      then: () => Yup.string().required("State is required"),
      otherwise: () => Yup.string().notRequired(),
    }),
    registered_address_city: Yup.string().when("registered_address_country", {
      is: "India",
      then: () => Yup.string().required("City is required"),
      otherwise: () => Yup.string().notRequired(),
    }),
    registered_address: Yup.string().required("Address is required"),
    contact_name: Yup.string().required("Contact Person Name is required"),
    contact_number: Yup.string().when("contact_cc", {
      is: "+91", // Condition: when primary_cc is '+91'
      then: () =>
        Yup.string()
          .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
          .required("Contact Person Number is required"),
      otherwise: () =>
        Yup.string()
          .matches(/^[0-9]{7,15}$/, "Please Enter a valid Phone Number")
          .required("Contact Person Number is required"),
    }),
    // end_date: Yup.date()
    //   .required("Subscription End Date is required")
    //   .min(Yup.ref("start_date"), "End Date cannot be before Start Date"),
  });

  const handleSubmit = async (values) => {
    setIsSaving(true);
    const formData = new FormData();

    Object.keys(values).forEach((key) => {
      let value = values[key];
      if (key === "contact_name" || key === "name") {
        value = capitalizeLetter(value);
        formData.append(key, value);
      } else if (key === "email" || key === "business_email") {
        value = value.toLowerCase();
        formData.append(key, value);
      } else if (values[key] !== null) {
        formData.append(key, value);
      }
    });

    files.forEach((file) => formData.append("files", file));
    try {
      const res = await postFormData({
        endpoint: "business/create",
        token: cookies.t,
        data: formData,
      });
      if (res) {
        toast.success("Business Created Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleEdit = async (values) => {
    const data = {
      ...values,
      contact_name: capitalizeLetter(values.contact_name),
      name: capitalizeLetter(values.name),
      email: values.email.toLowerCase(),
      business_email: values.business_email.toLowerCase(),
    };
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      let value = values[key];
      if (key === "contact_name" || key === "name") {
        value = capitalizeLetter(value);
        formData.append(key, value);
      } else if (key === "email" || key === "business_email") {
        value = value.toLowerCase();
        formData.append(key, value);
      } else if (values[key] !== null) {
        formData.append(key, value);
      }
    });
    formData.append("logo", fileUrls);
    files.forEach((file) => formData.append("files", file));

    try {
      const res = await patchFormData({
        endpoint: "business/update",
        token: cookies.t,
        data: formData,
        params: { id: id },
      });
      if (res) {
        toast.success("Business Updated Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const checkEmail = async (newVal) => {
    const response = await getData({
      endpoint: "business/checkEmail",
      token: cookies.t,
      params: { email: newVal },
    });
    if (response.status && response.data) {
      response.message[0] === "U"
        ? setEmailError(
            "User Is Mapped with Another Business,Use Different Email"
          )
        : setEmailError("Email Already Exists");
      setIsEmailChecked(false);
    } else {
      setEmailError("");
      setIsEmailChecked(false);
    }
  };

  const checkDomain = async (newVal) => {
    const response = await getData({
      endpoint: "business/checkDomain",
      token: cookies.t,
      params: { sub_domain: newVal },
    });
    if (response.status && response.data) {
      setDomainError("Sub-Domain Already Exists");
    } else {
      setDomainError(null);
    }
  };

  function extractCountryCode(str) {
    const match = str.match(/\(\+(\d+)\)/);
    return match ? `+${match[1]}` : null;
  }

  const handlePincodeChange = async (
    setFieldValue,
    setFieldTouched,
    value,
    addressType
  ) => {
    const pincode = value;
    setFieldValue(`${addressType}_pin`, pincode);
    if (pincode?.length === 6) {
      const pincodeData = await fetchPincodeService(pincode);
      if (pincodeData) {
        setFieldValue(`${addressType}_city`, pincodeData.District);
        setFieldValue(`${addressType}_state`, pincodeData.State);
        setFieldTouched(`${addressType}_state`, true);
        setFieldTouched(`${addressType}_city`, true);
        if (isSameAddress) {
          setFieldValue("registered_address_city", pincodeData.District);
          setFieldValue("registered_address_state", pincodeData.State);
          setFieldTouched("registered_address_state", true);
          setFieldTouched("registered_address_city", true);
        }
      }
    }
  };

  // function extractDataBetweenBrackets(inputString) {
  //   // Match the first occurrence of text within parentheses
  //   const match = /\(([^)]+)\)/.exec(inputString);

  //   console.log("Match :", Match);

  //   return match ? match[1].trim() : null; // Trim to remove any extra spaces
  // }

  const handleRemoveImage = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };

  const handleRemoveUrlFile = (fileUrl) => {
    setFileUrls(null);
  };

  const handleViewFile = (file) => {
    setSelectedFile(file);
    setIsModalOpen(true);
  };
  const handleViewFileURL = (file) => {
    setSelectedFile(file);
    setIsModalOpen(true);
    setIsViewURL(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedFile(null);
    setIsViewURL(false);
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur
      validateOnChange
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        isSubmitting,
        handleBlur,
        validateForm,
      }) => (
        <Form autoComplete="off">
          <>
            <Breadcrumb
              labels={[
                "Business",
                `${type === "add" ? "Add Business" : "Edit Business"}`,
              ]}
            />
            <Heading className="h5 mb-3 mt-3">
              {type === "add" ? "Add Business" : "Edit Business"}
            </Heading>
            <hr />

            {/* <div
            className="d-flex flex-wrap w-100 mb-4"
            style={{ columnGap: "1.36%", rowGap: "16px" }}
          >
            <div className="col-md">
              <label htmlFor="">Logo</label>
              <ImageSelector
                style={ImageSelectorStyle}
                type="Company"
                handleFile={(file) => {
                  setFieldValue("logo", file);
                  setFieldValue("remove_logo", false);
                }}
                aspect={2}
                widthLimit={600}
                heightLimit={400}
                selectedImage={replaceSpacesInImageUrl(initialData?.logo)}
                description="Logo image should be 600X400px and size is 1MB"
                onRemove={() => {
                  setFieldValue("remove_logo", true);
                }}
              />
            </div>

            <div className="col-md"></div>
          </div> */}

            <div
              className="d-flex flex-wrap w-100 mb-4"
              style={{ columnGap: "1.36%", rowGap: "16px" }}
            >
              <MyInput
                type="text"
                name="name"
                customholder="Business Name"
                width="32.426%"
                required
              />

              <MyInput
                type="text"
                name="business_email"
                customholder="Business Email"
                width="32.426%"
                required
              />

              <MyDropdown
                placeholder="Select Business Type"
                data={businessTypes}
                onChange={(option) => {
                  setFieldValue("business_type", option);
                }}
                handleBlur={(e) => {
                  setFieldTouched("business_type", true);
                  handleBlur(e);
                }}
                selectedValue={values?.business_type}
                name="business_type"
                touched={touched.business_type}
                errors={errors.business_type}
                width="32.426%"
                required
              />

              <div className="d-flex" style={{ width: "32.426%", gap: "4%" }}>
                <MyDropdown
                  data={countryCodeData}
                  selectedValue={values.primary_cc}
                  name="primary_cc"
                  width="20%"
                  type="Country"
                  onChange={(option) => {
                    setFieldValue("primary_cc", option);
                  }}
                  handleBlur={(e) => {
                    setFieldTouched("primary_cc", true);
                    handleBlur(e);
                  }}
                  selectedType="Country"
                />

                <MyInput
                  type="text"
                  name="primary_number"
                  customholder="Enter Primary Phone Number"
                  width="76%"
                  onChange={(e) => {
                    let numericInput = e.target.value.replace(/[^0-9]/g, "");
                    if (values.primary_cc === "+91") {
                      // Enforce a maximum length of 10 digits
                      if (numericInput.length > 10) {
                        numericInput = numericInput.slice(0, 10);
                      }
                      setFieldValue("primary_number", numericInput);
                    } else {
                      setFieldValue("primary_number", numericInput);
                    }
                  }}
                  required
                />
              </div>

              <div className="d-flex" style={{ width: "32.426%", gap: "4%" }}>
                {/* <MyInput type="text" name="alt_cc" width="23%" disabled /> */}
                <MyDropdown
                  data={countryCodeData}
                  selectedValue={values.alt_cc}
                  name="alt_cc"
                  width="20%"
                  type={"Country"}
                  onChange={(option) => {
                    setFieldValue("alt_cc", option);
                  }}
                  handleBlur={(e) => {
                    setFieldTouched("alt_cc", true);
                    handleBlur(e);
                  }}
                  selectedType="Country"
                />
                <MyInput
                  type="text"
                  name="alt_number"
                  customholder="Enter Alternate Phone Number"
                  width="76%"
                  onChange={(e) => {
                    let numericInput = e.target.value.replace(/[^0-9]/g, "");
                    if (values.alt_cc === "+91") {
                      // Enforce a maximum length of 10 digits
                      if (numericInput.length > 10) {
                        numericInput = numericInput.slice(0, 10);
                      }
                      setFieldValue("alt_number", numericInput);
                    } else {
                      setFieldValue("alt_number", numericInput);
                    }
                  }}
                />
              </div>

              <MyInput
                type="text"
                name="gst"
                width="32.426%"
                customholder="Enter GST No."
              />

              <MyInput
                type="text"
                name="sub_domain"
                width="32.426%"
                customholder="Enter Sub Domain"
                customBlur={(e) => {
                  if (initialValues.sub_domain !== values.sub_domain) {
                    checkDomain(values.sub_domain);
                  }
                }}
                blurError={domainError}
                required
              />
            </div>
            <div>
              <Heading className="h5 mb-3 mt-3">Upload Business Logo</Heading>
              <hr />
              <div
                className="d-flex flex-wrap w-100 mb-2"
                style={{ columnGap: "1.36%", rowGap: "16px" }}
              >
                <div style={{ width: "32.426%" }}>
                  <ImageSelector
                    placeholder="Upload Business Logo"
                    handleFile={(file) => {
                      if (fileUrls && fileUrls.length > 0) {
                        setFileUrls(null);
                        setFiles([file]);
                      } else {
                        setFiles([file]);
                      }
                    }}
                    maxFileSize={190 * 80}
                    requiredWidth={190}
                    requiredHeight={80}
                    touched={touched.client_supporting_docs}
                    errors={errors.client_supporting_docs}
                  />
                </div>

                {fileUrls &&
                  fileUrls.length > 0 &&
                  fileUrls.map((file, index) => (
                    <div
                      key={index}
                      style={{ width: "32.426%", position: "relative" }}
                    >
                      <div className={style.imagesNameStyle}>
                        <span className={style.imagesName}>{file.name}</span>
                        <div
                          className={style.closeIcon}
                          onClick={() => handleViewFileURL(file.url)}
                          style={{
                            position: "absolute",
                            top: "8px",
                            right: "30px",
                            cursor: "pointer",
                          }}
                        >
                          <EyeIcon />
                        </div>
                        <div
                          className={style.closeIcon}
                          onClick={() => handleRemoveUrlFile(file.url)}
                          style={{
                            position: "absolute",
                            top: "8px",
                            right: "5px",
                            cursor: "pointer",
                          }}
                        >
                          <Close />
                        </div>
                      </div>
                    </div>
                  ))}
                {files.map((file, index) => (
                  <div
                    key={index}
                    style={{ width: "32.426%", position: "relative" }}
                  >
                    <div className={style.imagesNameStyle}>
                      <span className={style.imagesName}>
                        {file.name || file.split("/").pop()}
                      </span>
                      <div
                        className={style.closeIcon}
                        onClick={() => handleViewFile(file)}
                        style={{
                          position: "absolute",
                          top: "8px",
                          right: "30px",
                          cursor: "pointer",
                        }}
                      >
                        <EyeIcon />
                      </div>
                      <div
                        className={style.closeIcon}
                        onClick={() => handleRemoveImage(file)}
                        style={{
                          position: "absolute",
                          top: "8px",
                          right: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <Close />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <Heading className="h5 mb-3 mt-3">Official Address</Heading>
            <hr />

            <div
              className="d-flex flex-wrap w-100"
              style={{ columnGap: "1.36%", rowGap: "16px" }}
            >
              <MyDropdown
                data={countryNameData}
                selectedValue={values.official_address_country}
                name="official_address_country"
                width="32.426%"
                placeholder="Select Country"
                onChange={(option) => {
                  setFieldValue("official_address_country", option);
                  if (isSameAddress)
                    setFieldValue("registered_address_country", option);
                }}
                handleBlur={(e) => {
                  setFieldTouched("official_address_country", true);
                  handleBlur(e);
                }}
                required
                touched={touched.official_address_country}
                errors={errors.official_address_country}
              />
              {values && values?.official_address_country === "India" && (
                <>
                  <MyInput
                    type="text"
                    name="official_address_pin"
                    customholder="Enter Pincode"
                    width="32.426%"
                    customChange={(e) => {
                      if (
                        initialValues?.official_address_pin !==
                        values?.official_address_pin
                      ) {
                        handlePincodeChange(
                          setFieldValue,
                          setFieldTouched,
                          e.target.value,
                          "official_address"
                        );
                      }
                      if (isSameAddress)
                        setFieldValue("registered_address_pin", e.target.value);
                    }}
                    required
                  />

                  <MyDropdown
                    data={stateNameslist}
                    selectedValue={values.official_address_state}
                    name="official_address_state"
                    width="32.426%"
                    placeholder="Select State"
                    onChange={(option) => {
                      setFieldValue("official_address_state", option);
                      if (isSameAddress)
                        setFieldValue("registered_address_state", option);
                    }}
                    handleBlur={(e) => {
                      setFieldTouched("official_address_state", true);
                      handleBlur(e);
                    }}
                    required
                    touched={touched.official_address_state}
                    errors={errors.official_address_state}
                  />

                  <MyInput
                    type="text"
                    name="official_address_city"
                    customholder="Enter City"
                    width="32.426%"
                    customChange={(e) => {
                      if (isSameAddress) {
                        setFieldValue(
                          "registered_address_city",
                          e.target.value
                        );
                      }
                    }}
                    required
                  />
                </>
              )}
              <MyTextArea
                as="textarea"
                customholder="Enter Address"
                name="official_address"
                style={{ width: "32.426%" }}
                required
                customChange={(e) => {
                  if (isSameAddress) {
                    setFieldValue("registered_address", e.target.value);
                  }
                }}
              />
            </div>

            <Heading className="h5 mb-3 mt-3">Registered Address</Heading>
            <hr />
            <div>
              <Checkbox
                name={"same_address"}
                children={"Same as an Official Address"}
                className="mb-3"
                checked={isSameAddress}
                onChange={() => {
                  setIsSameAddress(!isSameAddress);
                  setFieldValue("registered_address", values.official_address);
                  setFieldValue(
                    "registered_address_city",
                    values.official_address_city
                  );
                  setFieldValue(
                    "registered_address_country",
                    values.official_address_country
                  );
                  setFieldValue(
                    "registered_address_state",
                    values.official_address_state
                  );
                  setFieldValue(
                    "registered_address_pin",
                    values.official_address_pin
                  );
                  setFieldTouched("registered_address", true);
                  setFieldTouched("registered_address_city", true);
                  setFieldTouched("registered_address_country", true);
                  setFieldTouched("registered_address_state", true);
                  setFieldTouched("registered_address_pin", true);
                }}
              />
            </div>
            <div
              className="d-flex flex-wrap w-100"
              style={{ columnGap: "1.36%", rowGap: "16px" }}
            >
              <MyDropdown
                data={countryNameData}
                selectedValue={values.registered_address_country}
                name="registered_address_country"
                width="32.426%"
                placeholder="Select Country"
                onChange={(option) => {
                  setFieldValue("registered_address_country", option);
                }}
                handleBlur={(e) => {
                  setFieldTouched("registered_address_country", true);
                  handleBlur(e);
                }}
                isDisabled={isSameAddress}
                touched={touched.registered_address_country}
                errors={errors.registered_address_country}
                required
              />
              {values && values?.registered_address_country === "India" && (
                <>
                  <MyInput
                    type="text"
                    name="registered_address_pin"
                    customholder="Enter Pincode"
                    width="32.426%"
                    customChange={(e) => {
                      if (
                        initialValues?.registered_address_pin !==
                        values?.registered_address_pin
                      ) {
                        handlePincodeChange(
                          setFieldValue,
                          setFieldTouched,
                          e.target.value,
                          "registered_address"
                        );
                      }
                    }}
                    disabled={isSameAddress}
                    required
                  />

                  <MyDropdown
                    data={stateNameslist}
                    selectedValue={values.registered_address_state}
                    name="registered_address_state"
                    width="32.426%"
                    placeholder="Select State"
                    onChange={(option) => {
                      setFieldValue("registered_address_state", option);
                    }}
                    handleBlur={(e) => {
                      setFieldTouched("registered_address_state", true);
                      handleBlur(e);
                    }}
                    isDisabled={isSameAddress}
                    required
                    touched={touched.registered_address_state}
                    errors={errors.registered_address_state}
                  />

                  <MyInput
                    type="text"
                    name="registered_address_city"
                    customholder="Enter City"
                    width="32.426%"
                    disabled={isSameAddress}
                    required
                  />
                </>
              )}

              <MyTextArea
                as="textarea"
                style={{ width: "32.426%" }}
                customholder="Enter Address"
                name="registered_address"
                required
                disabled={isSameAddress}
              />
            </div>

            <Heading className="h5 mb-3 mt-3">Contact Details</Heading>
            <hr />

            <div
              className="d-flex flex-wrap w-100"
              style={{ columnGap: "1.36%", rowGap: "16px" }}
            >
              <MyInput
                type="text"
                name="contact_name"
                width="32.426%"
                customholder="Enter Contact Person Name"
                required
              />
              <div className="d-flex" style={{ width: "32.426%", gap: "4%" }}>
                <MyDropdown
                  data={countryCodeData}
                  selectedValue={values.contact_cc}
                  name="contact_cc"
                  width="20%"
                  type="Country"
                  onChange={(option) => {
                    setFieldValue("contact_cc", option);
                  }}
                  handleBlur={(e) => {
                    setFieldTouched("contact_cc", true);
                    handleBlur(e);
                  }}
                  selectedType="Country"
                />

                <MyInput
                  type="text"
                  name="contact_number"
                  customholder="Enter Contact Number"
                  width="76%"
                  onChange={(e) => {
                    let numericInput = e.target.value.replace(/[^0-9]/g, "");
                    if (values.contact_cc === "+91") {
                      // Enforce a maximum length of 10 digits
                      if (numericInput.length > 10) {
                        numericInput = numericInput.slice(0, 10);
                      }
                      setFieldValue("contact_number", numericInput);
                    } else {
                      setFieldValue("contact_number", numericInput);
                    }
                  }}
                  required
                />
              </div>
              <MyInput
                type="text"
                name="email"
                customholder="Email"
                width="32.426%"
                customChange={(e) => {
                  if (initialValues?.email !== e?.target?.value) {
                    checkEmail(e?.target?.value);
                  }
                }}
                blurError={emailError}
                required
                info="The Business will use this email to onboard."
              />

              {type === "edit" && values && values?.status !== "Pending" && (
                <MyDropdown
                  placeholder="Status"
                  data={statusData}
                  onChange={(option) => {
                    setFieldValue("status", option);
                  }}
                  handleBlur={(e) => {
                    setFieldTouched("status", true);
                    handleBlur(e);
                  }}
                  selectedValue={values?.status}
                  name="status"
                  touched={touched.status}
                  errors={errors.status}
                  width="32.426%"
                />
              )}
            </div>
            {role && role !== "Viewer" && (
              <>
                {console.log("Errors :", errors)}
                <div className="d-flex gap-3 mt-4">
                  {type === "add" ? (
                    <CustomButton
                      iconRequired={false}
                      type="btn-primary"
                      buttonType="submit"
                      handleClick={() => handleSubmit(values)}
                      disabled={
                        !isValid ||
                        !dirty ||
                        isSaving ||
                        emailError ||
                        isEmailChecked ||
                        domainError !== null
                      }
                    >
                      Create Business
                      {isSaving && (
                        <span
                          className="spinner-border spinner-border-sm ms-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </CustomButton>
                  ) : (
                    <CustomButton
                      iconRequired={false}
                      type="btn-primary"
                      buttonType="submit"
                      handleClick={() => handleEdit(values)}
                      disabled={
                        // !isValid ||
                        isSaving ||
                        (initialValues?.email !== values.email
                          ? emailError
                          : false) ||
                        (initialValues?.email !== values.email
                          ? isEmailChecked
                          : false) ||
                        domainError !== null
                      }
                    >
                      Update Business
                      {isSaving && (
                        <span
                          className="spinner-border spinner-border-sm ms-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </CustomButton>
                  )}

                  <CustomButton
                    text="Cancel"
                    iconRequired={false}
                    handleClick={() => {
                      navigate(-1);
                    }}
                  />
                </div>
                {isModalOpen && selectedFile && (
                  <Modal onClose={handleCloseModal} isActive={isModalOpen}>
                    {!isViewURL ? (
                      selectedFile.type.startsWith("image/") ? (
                        <img
                          src={URL.createObjectURL(selectedFile)}
                          alt="Preview"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        <iframe
                          src={URL.createObjectURL(selectedFile)}
                          title="Preview"
                          style={{ width: "100%", height: "500px" }}
                        />
                      )
                    ) : getExtension(selectedFile) !== "pdf" ? (
                      <div>
                        <img
                          src={selectedFile}
                          title="Preview"
                          className={style.view_selected_image}
                        />
                      </div>
                    ) : (
                      <iframe
                        src={selectedFile}
                        title="Preview"
                        style={{ width: "100%", height: "500px" }}
                      />
                    )}
                  </Modal>
                )}
              </>
            )}
          </>
        </Form>
      )}
    </Formik>
  );
};

export default Add;
