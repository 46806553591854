import { memo, useCallback, useEffect, useState } from "react";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import style from "./index.module.scss";
// import NoContentCard from "../../../atoms/NoContentCard";
import { deleteData, getData } from "../../../../Services";
import View from "../../../icons/View";
import EditBlue from "../../../icons/EditBlue";
import CustomButton from "../../../atoms/CustomButton";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Pagination from "../../../atoms/Pagination";
import Delete from "../../../icons/Delete";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import Modal from "../../../atoms/modals/DeleteModal";
import Edit from "../../../icons/EditBlue";
import CustomMenu from "../../../molecules/CustomMenu";
import HorizontalMenu from "../../../icons/HorizontalMenu";
import FilterComponent from "../../../molecules/filters/FilterComponent";
// import { Rate_TypeData } from "../../../../../utils/staticData";
import EyeIcon from "../../../icons/eye.icon";
import Upload from "../../../icons/Upload";
import { downloadReport } from "../../../../Services/downloadService";
import moment from "moment";
import NoContentCard from "../../../atoms/NoContentCard";
import KeyIcon from "../../../icons/key";

const Business = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t", "role"]);
  const role = cookies?.role ? atob(cookies.role) : "";
  const [businessData, setBusinessData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchData, setSearchData] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loader, setLoader] = useState(true);
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false,
  });
  const [filters, setFilters] = useState({});
  const [resendLoader, setResendLoader] = useState(false);

  const menu = [
    {
      key: "view",
      icon: <View height="20" width="20" />,
      label: "View",
      onClick: () => {
        navigate(`view/${selectedRow?._id}`);
      },
    },
    ...(role && role !== "Viewer"
      ? [
          {
            icon: <Edit height="20" width="20" />,
            label: "Edit",
            onClick: () => {
              navigate(`edit/${selectedRow?._id}`);
            },
          },
        ]
      : []),
    // ...(role && role !== "Viewer"
    //   ? [
    //       {
    //         icon: <Delete />,
    //         label: "Delete",
    //         onClick: () => {
    //           setModalStateDelete({
    //             isModalOpen: true,
    //             name: selectedRow.name,
    //             modalId: selectedRow._id,
    //           });
    //           setAnchorEl(null);
    //         },
    //       },
    //     ]
    //   : []),
    ...(role && role !== "Viewer"
      ? [
          {
            icon: <KeyIcon height="20" width="20" />,
            label: (
              <div className="d-flex align-items-center gap-1">
                Resend Invite
                {resendLoader && (
                  <span
                    className="spinner-border spinner-border-lr ms-2"
                    style={{ color: "#3b424a", width: "20px", height: "20px" }}
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </div>
            ),
            onClick: () => {
              handleResend(selectedRow._id);
            },
          },
        ]
      : []),
  ];

  const handleResend = async (newVal) => {
    setResendLoader(true);
    const response = await getData({
      endpoint: "business/resendInvite",
      params: { id: newVal },
      token: cookies.t,
    });
    if (response && response.status) {
      toast.success("Invite Resended Sucessfully", {
        style: confimationStyles,
        duration: 1000,
      });
      setAnchorEl(null);
    } else {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    }
    setResendLoader(false);
  };

  const GetbusinessData = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "business",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          rate_type: filters?.["Rate Type"],
        },
      });
      setBusinessData(res?.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    setLoader(false);
    GetbusinessData();
  }, [itemsPerPage, currentPage, searchData, filters]);

  const handleEdit = (data) => {
    navigate(`/dashboard/setting/price/add/${data?._id}`);
  };

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "name",
      headerName: "Business Name",
      flex: 1.2,
      sortable: true,
    },
    {
      field: "primary_phone",
      headerName: "Primary Phone No.",
      flex: 1,
      renderCell: (params) => {
        return (
          <span>
            {params.row.primary_number && params.row.primary_cc}{" "}
            {params.row.primary_number}
          </span>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.4,
    },
    {
      field: "contact_name",
      headerName: "Contact Person",
      sortable: true,
      flex: 1.2,
    },
    {
      field: "createdAt",
      headerName: "Created On",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.createdAt).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <>
            {params?.row.status === "Pending" ? (
              <span className="text-warning fw-bold">Pending</span>
            ) : params?.row.status === "Active" ? (
              <span className="text-success fw-bold">Active</span>
            ) : (
              <span className="text-error fw-bold">Inactive</span>
            )}
          </>
        );
      },
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {/* <div className="table-icons-container d-flex gap-1">
              <div onClick={() => navigate(`view/${params.row._id}`)}>
                <View />
              </div>
              <div onClick={() => navigate(`edit/${params.row._id}`)}>
                <Edit />
              </div>

              <div onClick={() => {}}>
                <Delete />
              </div>
            </div> */}
            <span
              type="button"
              onClick={(event) => handleStatusMenu(event, params.row)}
            >
              <HorizontalMenu />
            </span>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              items={menu}
            />
          </>
        );
      },
    },
  ];

  // const getMenuList = () => {
  //   if (roleData && roleData?.["add/edit"] && roleData?.delete) {
  //     return menu;
  //   } else if (roleData && roleData?.delete) {
  //     return [menu[1]];
  //   } else if (roleData && roleData?.["add/edit"]) {
  //     return [menu[0]];
  //   } else return [];
  // };

  const CustomNoRowsOverlay = memo(() => {
    return loader ? (
      <>
        {" "}
        <div
          className="w-100  d-flex align-items-center justify-content-center"
          style={{ height: "70vh" }}
        >
          <span
            className="spinner-border spinner-border-lr ms-2"
            style={{ color: "#3b424a", width: "50px", height: "50px" }}
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </>
    ) : (
      <>
        {!!searchData ? (
          <NoContentCard title="No Record Found" type="Pricing" />
        ) : (
          <NoContentCard
            title="No Business created yet!"
            tag="Add Business"
            type="Pricing"
            classes="d-flex align-items-center"
            {...(role && role !== "Viewer"
              ? {
                  handleClick: () => {
                    navigate("add");
                  },
                  subtitle:
                    "Currently no business created yet click on a add business button to create business",
                }
              : {})}
          />
        )}
      </>
    );
  });
  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };
  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: "business/delete",
        token: cookies.t,
        params: { id: modalStateDelete?.modalId },
      });
      if (res) {
        toast.success("Price Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseDelete();
        GetbusinessData();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const filterData = {
    // "Rate Type": Rate_TypeData,
  };

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "price/export",
      token: cookies.t,
      fileName: "Prices",
      params: {
        search: searchData,
        rate_type: filters?.["Rate Type"],
      },
    });

    if (res) {
      toast.success("Prices Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className="headingSearchWrap">
        <div>
          <Heading className="HeadingStyle">Business</Heading>
        </div>
        <div className="searchButton">
          <Search
            onSearchEmit={(data) => {
              setSearchData(data);
              setCurrentPage(1);
            }}
          />
          {/* <CustomButton
            text="Export"
            handleClick={() => handleDownload()}
            classes={"filter-contain-btn"}
            iconRequired
            icon={<Upload />}
          />

          <FilterComponent
            filterData={filterData}
            onFilterChange={(filter) => {
              setFilters(filter);
            }}
          /> */}
          {role && role !== "Viewer" && (
            <CustomButton
              text="Add Business"
              type="btn-primary"
              handleClick={() => {
                navigate("add");
              }}
            />
          )}
        </div>
      </div>
      <CustomDatagrid
        rows={businessData?.[0]?.roleData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        loader={loader}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {businessData?.[0]?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={businessData?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
      <Modal
        isActive={modalStateDelete.isModalOpen}
        onClose={handleCloseDelete}
        onClick={handleDelete}
        title="Business"
      />
    </>
  );
};
export default Business;
