import "./sidebar.scss";
import Dashboard from "../../icons/dashboard";
import CustomNavLink from "./CustomNavLink";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import useScreenWidth from "../../../hooks/useScreenwidth";
import CompleteLogo from "../../../assets/images/WEchartered_Logo.jpg";
import Configuration from "../../icons/Configuration";
import { Menu, MenuItem } from "@mui/material";
import LogoutModal from "../../atoms/modals/LogoutModal";
import { capitalizeLetter } from "../../../helpers/capatalizeLetters";
import { decodeCookieValue } from "../../../helpers/cookieHelper";
import { useCookies } from "react-cookie";
import UpwardArrow from "../../icons/UpwardArrow";
import Bottomarrow from "../../icons/Bottomarrow";
import CircleAvatar from "../../atoms/CircleAvatar";
import LeftArrow from "../../icons/LeftArrow";
import CompanyLogo from "../../icons/companyLogo";
import ShortCompantLogo from "../../icons/shortCompanyLogo";
import Counterparty from "../../icons/Counterparty";

const Sidebar = ({ isActive, setIsActive, sidebarRef }) => {
  const screenWidth = useScreenWidth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cookies, setCookies] = useCookies("un", "role");
  const role = cookies?.role ? atob(cookies.role) : "";
  const navigate = useNavigate();
  const handleProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const links = [
    {
      to: "/dashboard",
      label: "Dashboard",
      icon: <Dashboard />,
      alternatePath: "/dashboard",
    },
    {
      to: "/dashboard/business",
      label: "Business",
      icon: <Counterparty />,
      alternatePath: "/dashboard/business",
    },
    {
      label: "Settings",
      icon: <Configuration />,
      alternatePath: "/dashboard/setting",
      subLinks: [
        ...(role && role !== "Manager"
          ? [
              {
                to: "/dashboard/setting/user-management",
                label: "User Management",
              },
            ]
          : []),
        {
          to: "/dashboard/setting/category-management",
          label: "Expense Categories",
        },
      ],
    },
  ];

  const memoizedSetIsActive = useCallback((newIsActive) => {
    setIsActive(newIsActive);
  }, []);

  useEffect(() => {
    memoizedSetIsActive(screenWidth >= 1233);
  }, [screenWidth, memoizedSetIsActive]);
  const handleSideBar = () => {
    // if (screenWidth < 1233) setIsActive(false);
  };
  return (
    <>
      <div className={`sidebar ${!isActive ? "closed" : ""}`} ref={sidebarRef}>
        <div className="header">
          {isActive ? (
            // <img
            //   src={CompleteLogo}
            //   alt="Logo"
            //   className="open-header-img"
            //   onClick={() => navigate("/dashboard")}
            // />
            // <Logo1 />
            // <img src={Logo1} alt="Logo" />
            <CompanyLogo />
          ) : (
            // <> </>
            <ShortCompantLogo />
            // <img
            //   src={CompleteLogo}
            //   alt="Logo"
            //   className="closed-header-img"
            //   onClick={() => navigate("/dashboard")}
            // />
          )}
          <div
            className={`drawer-div ${!isActive ? "closed" : ""}`}
            onClick={() => setIsActive((prev) => !prev)}
          >
            <LeftArrow />
          </div>
        </div>
        <ul className="ul">
          {links.map((link, index) => (
            <CustomNavLink
              key={index}
              to={link.to}
              label={link.label}
              icon={link.icon}
              handleSideBar={handleSideBar}
              isSideBarActive={isActive}
              setIsSideBarActive={setIsActive}
            >
              {link.subLinks?.map((subLink, subIndex) => (
                <CustomNavLink
                  key={subIndex}
                  to={subLink.to}
                  label={subLink.label}
                  handleSideBar={handleSideBar}
                  isSideBarActive={isActive}
                />
              ))}
            </CustomNavLink>
          ))}
        </ul>
        <div className="profile mb-2" onClick={handleProfileMenu}>
          <div className="d-flex gap-2 align-items-center">
            <CircleAvatar
              color="#DEE3E7"
              iconColor="black"
              name={capitalizeLetter(decodeCookieValue(cookies.un))}
            />
            {isActive && (
              <span
                className="profile-span"
                title={capitalizeLetter(decodeCookieValue(cookies.un))}
              >
                {capitalizeLetter(decodeCookieValue(cookies.un))}
              </span>
            )}
          </div>

          {isActive && (anchorEl ? <UpwardArrow /> : <Bottomarrow />)}
        </div>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            style: {
              minWidth: "175px",
              borderRadius: "6px",
              boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
              marginBottom: "40px",
            },
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "bottom",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "bottom",
          }}
        >
          <MenuItem
            onClick={() => {
              navigate("/dashboard/edit-profile");
              setAnchorEl(null);
            }}
            style={{ fontWeight: 500 }}
          >
            <span>Edit Profile</span>
          </MenuItem>

          <MenuItem
            onClick={() => {
              setIsModalOpen(!isModalOpen);
              setAnchorEl(null);
            }}
            style={{ fontWeight: 500 }}
          >
            <span style={{ color: "#BE0F00" }}>Logout</span>
          </MenuItem>
        </Menu>

        {isModalOpen && (
          <LogoutModal
            isActive={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        )}
      </div>
    </>
  );
};
export default Sidebar;
