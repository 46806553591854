const statesData = [
    {
      name: "Andaman and Nicobar Islands",
    },
    {
      name: "Andhra Pradesh",
    },
    {
      name: "Arunachal Pradesh",
    },
    {
      name: "Assam",
    },
    {
      name: "Bihar",
    },
    {
      name: "Chandigarh",
    },
    {
      name: "Chhattisgarh",
    },
    {
      name: "Dadra and Nagar Haveli",
    },
    {
      name: "Daman and Diu",
    },
    {
      name: "Delhi",
    },
    {
      name: "Goa",
    },
    {
      name: "Gujarat",
    },
    {
      name: "Haryana",
    },
    {
      name: "Himachal Pradesh",
    },
    {
      name: "Jammu and Kashmir",
    },
    {
      name: "Jharkhand",
    },
    {
      name: "Karnataka",
    },
    {
      name: "Kerala",
    },
    {
      name: "Ladakh",
    },
    {
      name: "Lakshadweep",
    },
    {
      name: "Madhya Pradesh",
    },
    {
      name: "Maharashtra",
    },
    {
      name: "Manipur",
    },
    {
      name: "Meghalaya",
    },
    {
      name: "Mizoram",
    },
    {
      name: "Nagaland",
    },
    {
      name: "Odisha",
    },
    {
      name: "Puducherry",
    },
    {
      name: "Punjab",
    },
    {
      name: "Rajasthan",
    },
    {
      name: "Sikkim",
    },
    {
      name: "Tamil Nadu",
    },
    {
      name: "Telangana",
    },
    {
      name: "Tripura",
    },
    {
      name: "Uttar Pradesh",
    },
    {
      name: "Uttarakhand",
    },
    {
      name: "West Bengal",
    },
  ];
  export const stateNameslist = statesData?.map((item) => {
    return {
      label: item?.name,
      value: item?.name,
    };
  });