import { Navigate, Route, Routes } from "react-router-dom";
import Auth from "../Components/layout/AuthLayout";
import Dashboard from "../Components/layout/DashboardLayout";
import { Suspense } from "react";
import Login from "../Components/pages/Auth/Login";
import Verification from "../Components/pages/Auth/verifyOTP";
import ForgotPassword from "../Components/pages/Auth/forgotPassword";
import ResetOrCreatePass from "../Components/pages/Auth/createOrResetPassword";
import Business from "../Components/pages/Dashboards/Business";
import Add from "../Components/pages/Dashboards/Business/Add";
import UserManage from "../Components/pages/Dashboards/Settings/UserManagement";
import GuestGuard from "./GuestGuard";
import AuthGuard from "./AuthGuard";
import EditProfile from "../Components/pages/Dashboards/profileDetails/index";
import ChangePassword from "../Components/pages/Dashboards/profileDetails/change-password";
import ViewBusiness from "../Components/pages/Dashboards/Business/View";
import Home from "../Components/pages/Dashboards/Home";
import CategoryManagement from "../Components/pages/Dashboards/Settings/CategoryManagement";

const Authenticated = () => {
  const isAuthenticated = false;
  return isAuthenticated();
};

const routes = [
  {
    path: "/",
    element: <Navigate to="/auth" />,
  },
  {
    path: "auth/*",
    element: (
      <GuestGuard>
        <Auth />
      </GuestGuard>
    ),
  },
  {
    path: "dashboard/*",
    element: (
      <AuthGuard>
        <Dashboard />
      </AuthGuard>
    ),
    protected: true,
  },
];

export default routes;

export const authRoutes = (
  <Suspense>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/verify" element={<Verification />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route
        path="/reset-password"
        element={<ResetOrCreatePass type="reset" />}
      />
      <Route
        path="/create-password"
        element={<ResetOrCreatePass type="create" />}
      />
    </Routes>
  </Suspense>
);

export const dashboardRoutes = (
  <Suspense>
    <Routes>
      <Route path="/business" element={<Business />} />
      <Route path="/business/add" element={<Add type={"add"} />} />
      <Route path="/business/edit/:id?" element={<Add type={"edit"} />} />
      <Route path="/business/view/:id?" element={<ViewBusiness />} />
      <Route path="/setting/user-management" element={<UserManage />} />
      <Route path="change-password" element={<ChangePassword />} />
      <Route path="edit-profile" element={<EditProfile />} />
      <Route path="/" element={<Home />} />
      <Route path="home" element={<Home />} />
      <Route
        path="/setting/category-management"
        element={<CategoryManagement />}
      />
    </Routes>
  </Suspense>
);
