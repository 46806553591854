import React, { useCallback, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useCookies } from "react-cookie";
import * as Yup from "yup";
import { getData, patchData, postData } from "../../../../../Services";
import { statusData, userRoles } from "../../../../../helpers/staticData";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import MyInput from "../../../../atoms/MyInput";
import MyDropdown from "../../../../atoms/MyDropdown";
import { emailRegex } from "../../../../../helpers/expirationTime";
import { capitalizeLetter } from "../../../../../helpers/capatalizeLetters";

const UserForm = ({ onClose, initialValues, id, refetchData, setEditData }) => {
  const [cookies] = useCookies(["t", "role"]);
  const role = cookies?.role ? atob(cookies.role) : "";
  const [rolesData, setRolesData] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [roleGroupData, setRoleGroupData] = useState();

  const initialData = {
    name: initialValues?.name || "",
    email: initialValues?.email || "",
    role_id: initialValues?.role_id || "",
    status: initialValues?.status || "Pending",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("User Name is required"),
    email: Yup.string()
      .matches(emailRegex, "Invalid email")
      .required("Email is Required"),
    role_id: Yup.string().required("Role is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const res = await postData({
      endpoint: "adminAuth/createUser",
      token: cookies.t,
      data: {
        ...values,
        email: values.email.toLowerCase(),
        name: capitalizeLetter(values.name),
      },
    });
    if (res?.data?.statusCode && res?.data) {
      toast.success("User Added Successfully", {
        style: confimationStyles,
        duration: 1000,
      });

      resetForm();
      refetchData();
    } else {
      toast.error(res?.data?.message, { style: errorStyles, duration: 1000 });
      onClose();
    }
  };

  const handleEdit = async (values, { resetForm }) => {
    try {
      const res = await patchData({
        endpoint: "adminAuth/updateUser",
        token: cookies.t,
        data: {
          ...values,
          email: values.email.toLowerCase(),
          name: capitalizeLetter(values.name),
        },
      });

      if (res.status) {
        toast.success("User Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        resetForm();
        setEditData();
        refetchData();
      } else {
        toast.error(res.message, { style: confimationStyles, duration: 1000 });
      }

      onClose();
    } catch (error) {}
  };

  const checkEmail = async (newVal) => {
    const response = await getData({
      endpoint: "adminAuth/checkEmail",
      token: cookies.t,
      params: { email: newVal },
    });
    if (response.status && response.data) {
      response.message[0] === "U"
        ? setEmailError(
            "User Is Mapped with Another Business,Use Different Email"
          )
        : setEmailError("Email Already Exists");
    } else {
      setEmailError(null);
    }
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      key={id}
      enableReinitialize
      onSubmit={initialValues ? handleEdit : handleSubmit}
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
        isSubmitting,
        resetForm,
        setFieldTouched,
        touched,
      }) => (
        <Form>
          <div className="row mt-3">
            <div className="col-3">
              <MyInput
                type="text"
                id="name"
                name="name"
                totalWidth={true}
                customholder="Enter User Name"
                required
              />
            </div>

            <div className="col-3">
              <MyInput
                type="email"
                id="email"
                name="email"
                totalWidth={true}
                customholder="Enter Email"
                customBlur={(e) => {
                  if (initialValues?.email !== values?.email) {
                    checkEmail(values.email);
                  }
                }}
                blurError={emailError}
                required
              />
            </div>

            <div className="col-3">
              <MyDropdown
                data={userRoles}
                placeholder="Select Role"
                onChange={(option) => {
                  setFieldValue("role_id", option);
                }}
                selectedValue={values?.role_id}
                isError={errors.role_id}
                touched={touched.role_id}
                handleBlur={(e) => {
                  handleBlur(e);
                  setFieldTouched("role_id", true);
                }}
                errors={errors.role_id}
                required
              />
            </div>

            {initialValues && (
              <div className="col-3">
                <MyDropdown
                  data={statusData}
                  placeholder="Select Status"
                  onChange={(option) => {
                    setFieldValue("status", option);
                  }}
                  selectedValue={values?.status}
                />
              </div>
            )}
            {role && role !== "Viewer" && role !== "Manager" && (
              <div className={`col-3 ${initialValues ? "mt-3" : ""}`}>
                {initialValues ? (
                  <button
                    type="submit"
                    className="btn-smlg btn-primary w-50"
                    disabled={!isValid || isSubmitting || emailError}
                  >
                    Update
                    {isSubmitting && (
                      <span
                        className="spinner-border spinner-border-sm ms-2 text-light"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </button>
                ) : (
                  <div className="d-flex gap-4">
                    <button
                      type="button"
                      className="btn-smlg btn-outline-primary w-25 flex-1"
                      disabled={isSubmitting}
                      onClick={() => resetForm()}
                    >
                      Reset
                    </button>

                    <button
                      type="submit"
                      className="btn-smlg btn-primary w-25 flex-1"
                      disabled={isSubmitting || emailError}
                    >
                      Create
                      {isSubmitting && (
                        <span
                          className="spinner-border spinner-border-sm ms-2 text-light"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
